import {IconButton, ButtonProps, MenuItem, MenuList, Popover, SxProps} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React, {useState} from 'react';
import {isDesktop} from 'react-device-detect';
const linkedButtonIconStyle: SxProps = {
  height: '100%',
  borderRadius: 0,
  paddingLeft: '8px',
  paddingRight: '8px',
  minWidth: '24px',
  '&:hover': {
    backgroundColor: 'inherit',
  },
};
const linkIconStyle: SxProps = {
  // MuiV4との混在でThemeが適用されないことがあるためtheme.palette.grey[700]を直接指定
  color: '#46484B',
};

/** MEMO:SPで他のページに遷移してから戻るとMUIデフォルトのCSSが書き換わる為、再定義 */
const menuListStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& a': {
    padding: '6px 16px',
  },
};

// TODO:Propsで受け取るように変更して汎用性を持たせる
const menuLinks = [
  {label: 'マニュアル・FAQ', url: 'https://hitotsu.tayori.com/q/asset-manual/'},
  {label: 'HITOTSUからのお知らせ', url: 'https://hitotsu.tayori.com/q/hitotsu-info/'},
];

const HelpIconButton = (props: ButtonProps) => {
  return (
    <IconButton {...props} color="inherit" sx={linkedButtonIconStyle} size="small">
      <HelpIcon sx={linkIconStyle} />
    </IconButton>
  );
};

/**
 * ヘルプアイコン(?)ボタン表示コンポーネント
 * マウスオーバー(タップ)でリンクのポップアップメニューを表示する
 *
 * @see https://mui.com/material-ui/react-popover/
 */
export const SupportPageButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    anchorEl?.focus();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <HelpIconButton onMouseEnter={isDesktop ? handleOpenMenu : undefined} onClick={handleOpenMenu} />
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseMenu}>
        <MenuList sx={isDesktop ? undefined : menuListStyles}>
          {menuLinks.map((link, index) => (
            <MenuItem key={`link${index}`} component="a" href={link.url} target="_blank" rel="noopener noreferrer">
              {link.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};
