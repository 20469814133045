import React from 'react';
import {Accordion} from '@components/molecules/Accordion/AccordionV5';
import {useStoredAccordionSetting} from '@modules/localstorage';

type Props = {
  settingKey: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
};

/**
 * 折りたたみ可能なヘッダーコンポーネント
 * `settingKey`を利用して開閉状態を永続化する
 * src/components/organisms/CollapseHeader/index.tsx
 * 。
 *
 * @param props.children - 折りたたみ可能なコンテンツ
 * @param props.settingKey - 折りたたみ状態を永続化するためのキー
 * @param props.defaultOpen - 初期状態で折りたたみが展開されているかを指定
 *
 * @example
 * ```tsx
 * const Example = () => (
 *   <CollapseHeader settingKey="example_key" defaultOpen={true}>
 *     <div>Content goes here</div>
 *   </CollapseHeader>
 * );
 * ```
 */
export const CollapseHeader = (props: Props) => {
  const {children, settingKey, defaultOpen} = props;

  const accordionSettingProps = useStoredAccordionSetting(settingKey, defaultOpen);

  return <Accordion {...accordionSettingProps}>{children}</Accordion>;
};
