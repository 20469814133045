import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ListPageLayout} from '@components/layouts/ListPageLayout/ListPageLayoutV5';
import {Box, SxProps, Typography} from '@mui/material';
import {HeaderBackwardButton} from '@components/molecules/Buttons/HeaderBackwardButton';
import {ReturnWaitingReceptionListTable} from './ReturnWaitingReceptionListTable';

const headerLabelStyle: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
};

const headerComponentStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  padding: '8px 24px 16px',
  width: '100%',
};

const Header = () => {
  const navigate = useNavigate();
  return (
    <Box sx={headerComponentStyle}>
      <Box>
        <HeaderBackwardButton label="貸出・返却一覧に戻る" onClick={() => navigate('/rentals')} />
        <Typography variant="h1" sx={headerLabelStyle}>
          返却待ち受付
        </Typography>
      </Box>
    </Box>
  );
};

export const ReturnWaitingReceptionList = () => {
  return (
    <ListPageLayout page="return_waiting_reception_list">
      <ListPageLayout.Header>
        <Header />
      </ListPageLayout.Header>
      <ListPageLayout.Content>
        <ReturnWaitingReceptionListTable />
      </ListPageLayout.Content>
    </ListPageLayout>
  );
};
