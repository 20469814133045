import React from 'react';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';
import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export const ReturnWaitingReceptionComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <FaultReceptionHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '50vh auto 0',
          transform: 'translateY(-50%)',
          fontWeight: 700,
          fontSize: '40px',
        }}>
        返却待ち受付が完了しました
        <Button color="primary" variant="contained" size="large" onClick={() => navigate('/shared/reception_menu')}>
          終了する
        </Button>
      </Box>
    </>
  );
};
