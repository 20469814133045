import React, {useCallback, useMemo} from 'react';
import {Tooltip, styled, Button} from '@mui/material';
import {Badge} from '@atoms/Badge';
import linkLogo from '@assets/link_logo.png';
import {useFetchLinkUnreadChatMessageCountsQuery} from '@modules/link_notifications/api';

const StyledButton = styled(Button)({
  height: '100%',
  borderRadius: 0,
  paddingRight: '16px',
  paddingLeft: '16px',
  minWidth: '24px',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});

const StyledLinkLogo = styled('img')({
  width: '24px',
  border: '1px solid #D1D5DB',
  borderRadius: '6px',
});

type LinkNotificationLogoProps = {
  tooltipOffset?: [number, number];
};

export const LinkNotificationLogo = ({tooltipOffset = [0, 0]}: LinkNotificationLogoProps) => {
  const {data} = useFetchLinkUnreadChatMessageCountsQuery();

  const handleClickLinkNotification = useCallback(() => {
    window.open(`${import.meta.env.VITE_LINK_URL}`, '_blank');
  }, []);

  const totalCount = useMemo(() => {
    return data?.self.unreadChatMessageCounts.unreadMessageCounts.reduce((acc, curr) => acc + curr.count, 0) ?? 0;
  }, [data]);

  return (
    <Tooltip
      title="新しいタブでLinkを開く"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: tooltipOffset,
              },
            },
          ],
        },
      }}>
      <StyledButton color="inherit" onClick={handleClickLinkNotification}>
        <Badge badgeContent={totalCount} color={'secondary'}>
          <StyledLinkLogo src={linkLogo} alt="link_logo" />
        </Badge>
      </StyledButton>
    </Tooltip>
  );
};
