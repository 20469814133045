import React from 'react';
import {MobileProductFormField} from '@molecules/MobileProductFormField';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useFetchHospitalProductQuery} from '@modules/hospital_products/api';
import {HospitalProductDetail} from '@modules/hospital_products/types';
import {HospitalRoomFormatter} from '@modules/hospital_wards/helpers';
import {convertDateToJapaneseDate, convertRFC3339ToDate, isNullish} from '@front-libs/helpers';
import {InnerLoading} from '@molecules/Loading';
import {MEIDAI_HIT_5368_IS_BASE_UNIT, productStatusOptions} from '@constants/constants';

const fields = [
  {
    type: 'text',
    label: '管理番号',
    name: 'managementId',
    getValue: (product: HospitalProductDetail) => product.managementId,
  },
  {
    type: 'text',
    label: 'シリアル番号',
    name: 'serialNumber',
    getValue: (product: HospitalProductDetail) => product.serialNumber,
  },
  {
    type: 'text',
    label: 'ロット番号',
    name: 'lotNumber',
    getValue: (product: HospitalProductDetail) => product.lotNumber ?? '',
  },
  {
    type: 'select',
    label: '貸出区分',
    name: 'permanentlyAssigned',
    getValue: (product: HospitalProductDetail) => (product.permanentlyAssigned ? '貸出不可' : '貸出可'),
  },
  {
    type: 'text',
    label: '稼働状況',
    name: 'status',
    getValue: (product: HospitalProductDetail) =>
      productStatusOptions.find((item) => item.value === product.status)?.label ?? '',
  },
  {
    type: 'text',
    label: '機器管理場所',
    name: 'hospitalRoomHashId',
    getValue: (product: HospitalProductDetail) => HospitalRoomFormatter.getFullRoom(product.hospitalRoom) ?? '',
  },
  {
    type: 'text',
    label: '貸出場所',
    name: 'rentHospitalRoomHashId',
    getValue: (product: HospitalProductDetail) => HospitalRoomFormatter.getFullRoom(product.rentHospitalRoom) ?? '',
  },
  {
    type: 'text',
    label: '親機・子機',
    name: 'isBaseUnit',
    getValue: (product: HospitalProductDetail) => {
      // Featureフラグがfalseなら既存の動作
      if (!MEIDAI_HIT_5368_IS_BASE_UNIT) {
        if (isNullish(product.isBaseUnit)) return '';
        return product.isBaseUnit ? '親機' : '子機';
      }
      if (isNullish(product.isBaseUnit)) return '非該当';
      else if (product.isBaseUnit) {
        return '親機';
      }
      return '子機';
    },
  },
  {
    type: 'text',
    label: '購入日',
    name: 'dateOfPurchase',
    getValue: (product: HospitalProductDetail) => {
      const date = convertRFC3339ToDate(product.dateOfPurchase);
      if (isNullish(date)) return '';
      return convertDateToJapaneseDate(date) ?? '';
    },
  },
  {
    type: 'text',
    label: 'バーコード読み取り値',
    name: 'optionalBarcode',
    getValue: (product: HospitalProductDetail) => product.optionalBarcode ?? '',
  },
  {
    type: 'text',
    label: 'GS1バーコード',
    name: 'gs1Barcode',
    getValue: (product: HospitalProductDetail) => product.gs1Barcode ?? '',
  },
  // FIXME: 販売代理店を追加する
];

type BasicHospitalProductInfoProps = {
  hospitalProductHashId: string;
};

export const BasicHospitalProductInfo = (props: BasicHospitalProductInfoProps) => {
  const {hospitalProductHashId} = props;
  const {myInfo} = useMyInfo();

  const {data, isLoading: isLoadingHospitalProduct} = useFetchHospitalProductQuery(
    myInfo.hospitalHashId,
    hospitalProductHashId
  );

  if (isLoadingHospitalProduct || isNullish(data)) {
    return <InnerLoading />;
  }

  return (
    <div style={{padding: '16px'}}>
      {fields.map((item, index) => (
        <React.Fragment key={`item${index}`}>
          {item && (
            <div style={{marginBottom: 24}}>
              <MobileProductFormField label={item.label} value={item.getValue(data)} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
