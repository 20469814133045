import React from 'react';
import {Box, Paper, styled} from '@mui/material';
import {Content} from './Content';
import {Footer} from './Footer';
import {FaultReceptionHeader} from '@components/molecules/FaultReceptionHeader';

const StyledBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const PaperContainer = styled(Paper)({
  backgroundColor: 'white',
  height: 'calc(100vh - 142px)', // Header 66 とMargin 32 Footer 44 を引いた値
  maxWidth: '860px',
  width: '90%',
  margin: '32px auto',
  borderRadius: '10px',
});

/**
 * 貸出可能機器の閲覧画面
 * @returns
 */
export const RentableDevices = () => {
  return (
    <StyledBody>
      <FaultReceptionHeader title="貸出可能機器の閲覧" />
      <PaperContainer>
        <Content />
      </PaperContainer>
      <Footer />
    </StyledBody>
  );
};
