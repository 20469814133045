import React, {useEffect, useState} from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  SxProps,
  Theme,
  PaperProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import {NoContentMessage} from './NoContentMessage';

export type TableOrder<T = string> = {
  fieldId: keyof T;
  direction: 'asc' | 'desc';
};

type EnhancedTableProps<T extends object> = {
  data: T[]; // テーブルに表示するデータ ジェネリクスで型を指定
  columns: EnhancedColumn<T>[]; // テーブルの列定義
  order?: TableOrder<T> | null; // ソート順を呼び出し元で制御する場合に使用
  defaultOrder?: TableOrder<T>; // 初期ソート順
  onOrderChange?: (column: keyof T, orderDirection: 'asc' | 'desc') => void; // ソートが変更された時のコールバック
  isLoading?: boolean; // trueの時はローディング中の表示を行う
  stickyHeader?: boolean; // ヘッダーを固定するかどうか
  stickyColumn?: boolean; // 列を固定するかどうか
  options?: TableOptions; // テーブル全体のオプション
  noDataComponent?: React.ReactNode; // データがない時に表示するコンポーネント 未指定ならデフォルトのメッセージを表示
};

const wrapperStyle: SxProps = {
  display: 'inline-block',
  maxWidth: '40ch', // 日本語は20文字程度
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
};

export type EnhancedColumn<T extends object> = {
  title?: string;
  field: keyof T;
  //従来のrenderの機能 コンポーネントを表示したり変換したstring(末尾に円をつける等)を表示する時に使用
  fieldValue?: (row: T) => React.ReactNode;
  noBodyWrap?: boolean; //セル内で改行しないときはtrue
  sorting?: boolean; //ソートを有効にするかどうか
  justifyContentBody?: 'left' | 'right' | 'center';
  stickyHeader?: boolean;
  stickyColum?: boolean;
  sx?: SxProps<Theme>;
};

export type TableOptions = {
  size?: 'small' | 'medium';
  tableSx?: SxProps<Theme>;
  paperProps?: PaperProps;
};

/**
 * 汎用的なテーブルコンポーネント。
 *
 * @todo チェックボックス マウスオーバーでボタンが出る対応 StickerHeader追加 列にSticker追加
 * @type T テーブルの行データの型。
 * @param props.data テーブルに表示するデータの配列。
 * @param props.columns テーブルの列定義の配列。
 * @param props.options テーブル全体のオプションを追加。
 * @example
 *
 * ```tsx
 * type DataType ={
 *  id: number;
 *  name: string;
 * }
 * const data:DataType[] = [
 *   { id: 1, name: 'Aさん', age: 30 },
 *   { id: 2, name: 'Bさん', age: 25 },
 * ];
 * const columns = EnhancedColumn<DataType>[] = [
 *   { title: 'ID', field: 'id', sorting: true },
 *   { title: 'Name', field: 'name',sx: {minWidth: '180px'}, },
 *   { title: 'Age', field: 'age' },
 * ];
 *
 * <EnhancedTable<DataType>
 *   data={data}
 *   columns={columns}
 *   defaultOrder={{ fieldId: 'name', direction: 'asc' }}
 *   onOrderChange={(column, direction) => console.log(column, direction)}
 * />
 * ```
 */
export const EnhancedTable = <T extends object>({
  data,
  columns,
  isLoading,
  noDataComponent,
  order,
  defaultOrder,
  stickyHeader = false,
  stickyColumn = false,
  onOrderChange,
  options,
}: EnhancedTableProps<T>) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(defaultOrder?.direction ?? order?.direction ?? 'asc');
  const [orderBy, setOrderBy] = useState<keyof T | null>(
    (defaultOrder?.fieldId as keyof T) ?? (order?.fieldId as keyof T) ?? null
  );

  useEffect(() => {
    if (!order) return;
    setOrderBy(order.fieldId as keyof T);
    setSortOrder(order.direction);
  }, [order]);

  const handleSort = (field: keyof T) => {
    // orderが定義されていたら定義側の情報を優先する
    const isAsc = (order?.fieldId === field || orderBy === field) && (order?.direction || sortOrder) === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newOrder);
    setOrderBy(field);
    if (onOrderChange) {
      onOrderChange(field, newOrder);
    }
  };

  /** orderが未定義の時にdataをソートする */
  const sortedData = React.useMemo(() => {
    if (order || !orderBy) {
      return data;
    }

    return [...data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, order, orderBy, sortOrder]);

  const hasData = !isLoading && data.length > 0;
  const isNoData = !isLoading && data.length === 0;
  const isDataLoading = !isNoData && isLoading;

  const getColumnValue = (column: EnhancedColumn<T>, row: T) => {
    return column.fieldValue ? column.fieldValue(row) : column.field && row[column.field];
  };

  return (
    <TableContainer component={Paper} sx={{height: '100%'}} {...options?.paperProps}>
      <Table
        size={options?.size ?? 'small'}
        sx={{...options?.tableSx}}
        stickyHeader={stickyHeader}
        aria-label="enhanced table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align={column.justifyContentBody || 'left'}
                sx={{
                  whiteSpace: column.noBodyWrap ? 'nowrap' : 'normal',
                  ...column.sx,
                  ...(index === 0 && stickyColumn
                    ? {position: 'sticky', left: 0, background: '#FFFDF1', zIndex: 100}
                    : {}),
                  ...(index === 1 && stickyColumn
                    ? {position: 'sticky', left: 131, background: '#f1f1ff', zIndex: 150}
                    : {}),
                  ...(index === 2 && stickyColumn
                    ? {position: 'sticky', left: 310, background: '#fff1f1', zIndex: 180}
                    : {}),
                  ...(index === columns.length - 1 && stickyColumn
                    ? {position: 'sticky', right: 0, background: '#f1f1ff', zIndex: 200}
                    : {}),
                }}>
                <TableSortLabel
                  active={column.sorting && orderBy === column.field}
                  direction={sortOrder}
                  onClick={() => column.sorting && handleSort(column.field as keyof T)}
                  disabled={!column.sorting}
                  hideSortIcon={column.sorting === false || !column.sorting}>
                  {column.title || ''}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* ローディング中 */}
          {isDataLoading && (
            <CenterTableRow colSpan={columns.length}>
              <CircularProgress sx={{color: '#0052CC', margin: '16px 0'}} />
            </CenterTableRow>
          )}
          {/* 対象のデータなし */}
          {isNoData && (
            <CenterTableRow colSpan={columns.length}>
              {noDataComponent || <NoContentMessage title={'データがありません'} message={''} />}
            </CenterTableRow>
          )}

          {hasData &&
            sortedData.map((row, index) => (
              <TableRow key={index} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                {columns.map((column, colIndex) => {
                  const value = getColumnValue(column, row);
                  return (
                    <TableCell
                      key={colIndex}
                      align={column.justifyContentBody || 'left'}
                      sx={{
                        whiteSpace: column.noBodyWrap ? 'nowrap' : 'normal',
                        ...column.sx,
                        ...(colIndex === 0 && stickyColumn ? {position: 'sticky', left: 0, background: '#FFFDF1'} : {}),
                        ...(colIndex === 1 && stickyColumn
                          ? {position: 'sticky', left: 131, background: '#f1f1ff', zIndex: 100}
                          : {}),
                        ...(colIndex === 2 && stickyColumn
                          ? {position: 'sticky', left: 310, background: '#fff1f1', zIndex: 110}
                          : {}),
                        ...(colIndex === columns.length - 1 && stickyColumn
                          ? {position: 'sticky', right: 0, background: '#f1f1ff', zIndex: 120}
                          : {}),
                      }}>
                      {typeof value === 'string' ? (
                        <Typography component="span" sx={wrapperStyle}>
                          {value}
                        </Typography>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
// sticky: {
//   position: "sticky",
//   left: 0,
//   background: "#FFFDF1",
//   boxShadow: "5px 2px 5px grey",
//   borderRight: "0px solid black"
// }
type CenterTableRowProps = {
  colSpan: number;
  children: React.ReactNode;
};
const CenterTableRow = ({colSpan, children}: CenterTableRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
};
