import {Button, SxProps, Typography} from '@mui/material';
import React from 'react';

type CountOutlinedButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  count?: number;
};

const buttonStyle: SxProps = {
  fontSize: '14px',
  backgroundColor: 'white',
};
const countStyle: SxProps = {
  backgroundColor: '#0052CC',
  borderRadius: '14px',
  fontSize: '14px',
  color: 'white',
  padding: '0px 8px',
  marginLeft: '8px',
  minWidth: '14px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * ボタンに件数を表示するコンポーネント
 * ボタンはアウトラインスタイル
 */
export const CountOutlinedButton = ({children, onClick, count}: CountOutlinedButtonProps) => {
  return (
    <Button variant="outlined" color="primary" onClick={onClick} sx={buttonStyle}>
      {children}
      {count !== 0 && (
        <Typography component="span" sx={countStyle}>
          {count}
        </Typography>
      )}
    </Button>
  );
};
