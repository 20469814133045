type ReceptionSelectionMapType = {
  title: string;
  footerTitle: {
    selection: string;
    input: string;
    preview: string;
  };
  rootPath: string;
  path: {
    wardSelection: string;
    roomSelection: string;
    floorSelection: string;
    input: string;
    preview: string;
    complete: string;
  };
  label: {
    reservationCount: string;
    contactInformation: string;
    transitionMessage: string;
    nextPreview: string;
  };
};

/** 返却待ち受付の定数 */
export const ReceptionSelectionMap: ReceptionSelectionMapType = {
  title: '返却待ち受付',
  footerTitle: {
    selection: '機器を使用する場所を選択してください',
    input: '返却待ち受付に必要な情報を入力してください',
    preview: '入力内容を確認し、\n「返却待ち受付をする」ボタンを押してください',
  },
  rootPath: '/shared/return_waiting_reception',
  path: {
    wardSelection: '/shared/return_waiting_reception/ward_selection',
    roomSelection: '/shared/return_waiting_reception/room_selection',
    floorSelection: '/shared/return_waiting_reception/floor_selection',
    input: '/shared/return_waiting_reception/register/input',
    preview: '/shared/return_waiting_reception/register/preview',
    complete: '/shared/return_waiting_reception/register/complete',
  },
  label: {
    reservationCount: '受付台数は何台ですか？',
    contactInformation: '連絡先を入力してください',
    transitionMessage: '伝達事項があれば入力してください',
    nextPreview: '確認画面へ',
  },
};

export const MAX_SELECTION_PER_PAGE = 18;
