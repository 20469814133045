import {EnhancedTable, EnhancedColumn, NoContentMessage} from '@front-libs/ui';
import React from 'react';
import {TableLayout, useTableLayout} from '@modules/table_layout/hooks/useTableLayout';
import dayjs from 'dayjs';
import {Box, Button} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
/** 完了 連絡済み 準備中 確認前 */
type ResponseStatusType = 'completed' | 'contacted' | 'in_preparation' | 'pending_verification';

type StatusInfo = {
  name: string;
  backGroundColor: string;
  color: string;
};

const statusMap: Record<ResponseStatusType, StatusInfo> = {
  completed: {name: '完了', backGroundColor: 'rgb(237,247,237)', color: '#3B873E'},
  contacted: {name: '連絡済み', backGroundColor: 'rgb(233,244,254)', color: '#0B79D0'},
  in_preparation: {name: '準備中', backGroundColor: 'rgb(255,245,229) ', color: '#C77700'},
  pending_verification: {name: '確認前', backGroundColor: 'rgb(245,245,245)', color: '#616161'},
};

type DummyResponse = {
  hashId: string;
  /** 受付日時 */
  receptionDate: string;
  /** 対応ステータス */
  responseStatus: ResponseStatusType;
  /** 機器を使用する場所 */
  usageLocation: string;
  /** 対象機器（小分類） */
  targetEquipment: string;
  /** 受付台数 */
  numberOfDevices: number;
  /** 連絡先 */
  contact: string;
  /** 病棟からの伝達事項 */
  wardRemarks: string;
  /** 病棟への連絡者 */
  wardContactPerson: string;
  /** コメント */
  comments: string;
};

type ResponseAddComponent = DummyResponse & {editButton?: string};

const dummyData: DummyResponse[] = [
  {
    hashId: 'lBoVVuPdX4ZY5ulk',
    receptionDate: '2024-04-14T21:14:16.578Z',
    responseStatus: 'contacted',
    usageLocation: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
    targetEquipment: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
    numberOfDevices: 5,
    contact: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
    wardRemarks: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
    wardContactPerson: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
    comments: 'このテキスト１０文字このテキスト１０文字このテキスト１０文字',
  },
  {
    hashId: 'VmBuxar1b73Azglw',
    receptionDate: '2023-04-15T11:30:07.625Z',
    responseStatus: 'contacted',
    usageLocation: '病棟C',
    targetEquipment: '心電計',
    numberOfDevices: 5,
    contact: '080-1379-9138',
    wardRemarks: '特に問題なし',
    wardContactPerson: '田中 花子',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'dseqha4KwbNmTIAw',
    receptionDate: '2024-03-05T21:49:39.912Z',
    responseStatus: 'contacted',
    usageLocation: '病棟B',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 4,
    contact: '080-3978-9214',
    wardRemarks: '特に問題なし',
    wardContactPerson: '鈴木 一郎',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: '1v7QdxPQIsSwqxiO',
    receptionDate: '2022-01-10T16:43:17.203Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟C',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 4,
    contact: '5268',
    wardRemarks: '特に問題なし',
    wardContactPerson: '佐藤 美咲',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'zeWQkwn4tTBZUDvq',
    receptionDate: '2023-04-13T01:15:03.580Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟A',
    targetEquipment: '心電計',
    numberOfDevices: 4,
    contact: '080-7505-6829',
    wardRemarks: '特に問題なし',
    wardContactPerson: '高橋 翔太',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: '9FI2i3PqUMzNEBuU',
    receptionDate: '2022-10-20T21:20:05.696Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟A',
    targetEquipment: '心電計',
    numberOfDevices: 3,
    contact: '7057',
    wardRemarks: '特に問題なし',
    wardContactPerson: '伊藤 愛',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'VJQflBdA9XTiKIkg',
    receptionDate: '2023-05-13T17:58:24.387Z',
    responseStatus: 'completed',
    usageLocation: '病棟B',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 2,
    contact: '3746',
    wardRemarks: '特に問題なし',
    wardContactPerson: '渡辺 陽菜',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'fs8jwb8cnWE5TLos',
    receptionDate: '2022-05-17T20:16:51.896Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟C',
    targetEquipment: '心電計',
    numberOfDevices: 1,
    contact: '090-4295-5354',
    wardRemarks: '特に問題なし',
    wardContactPerson: '小林 裕太',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: '3aq0Ag4bYlQPV1us',
    receptionDate: '2023-04-05T10:53:23.082Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟A',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 4,
    contact: '080-7973-1041',
    wardRemarks: '特に問題なし',
    wardContactPerson: '加藤 美優',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'Bd3cTxaepB0N29eq',
    receptionDate: '2022-03-04T15:33:22.048Z',
    responseStatus: 'completed',
    usageLocation: '病棟B',
    targetEquipment: '心電計',
    numberOfDevices: 2,
    contact: '070-7524-7573',
    wardRemarks: '特に問題なし',
    wardContactPerson: '中村 大輔',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 've5MTrqiOPjIxV0S',
    receptionDate: '2022-08-04T13:46:13.763Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟B',
    targetEquipment: '心電計',
    numberOfDevices: 4,
    contact: '9985',
    wardRemarks: '特に問題なし',
    wardContactPerson: '藤井 茜',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'sEYhpoCAuJglZLoM',
    receptionDate: '2022-07-19T15:10:11.651Z',
    responseStatus: 'contacted',
    usageLocation: '病棟B',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 5,
    contact: '2667',
    wardRemarks: '特に問題なし',
    wardContactPerson: '斉藤 拓海',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'no7NgMLA6wF57gyG',
    receptionDate: '2024-09-23T01:38:44.496Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟B',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 2,
    contact: '5553',
    wardRemarks: '特に問題なし',
    wardContactPerson: '山口 奈々',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'KTatHuqkjpDJp7Fk',
    receptionDate: '2024-03-29T14:32:34.416Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟C',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 4,
    contact: '070-8013-2835',
    wardRemarks: '特に問題なし',
    wardContactPerson: '松本 優希',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'l4Jr2djr9fRVlv36',
    receptionDate: '2023-05-11T22:34:16.467Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟B',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '3499',
    wardRemarks: '特に問題なし',
    wardContactPerson: '井上 健',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'yKd68lhuJCQgLK5j',
    receptionDate: '2024-06-20T14:08:49.725Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟C',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 1,
    contact: '8223',
    wardRemarks: '特に問題なし',
    wardContactPerson: '木村 千尋',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'Qb7WlhZ26MwtOzW4',
    receptionDate: '2023-08-01T13:05:26.404Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟A',
    targetEquipment: 'モニター',
    numberOfDevices: 4,
    contact: '4047',
    wardRemarks: '特に問題なし',
    wardContactPerson: '林 萌花',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'CD9HHq0XUM3ZI8Xs',
    receptionDate: '2024-02-04T10:07:29.155Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟B',
    targetEquipment: '心電計',
    numberOfDevices: 3,
    contact: '6264',
    wardRemarks: '特に問題なし',
    wardContactPerson: '清水 健太',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'QuqjUNMEB4QMFh1w',
    receptionDate: '2022-04-06T00:49:47.837Z',
    responseStatus: 'contacted',
    usageLocation: '病棟A',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 5,
    contact: '080-6685-1696',
    wardRemarks: '特に問題なし',
    wardContactPerson: '森田 梨花',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: '89WbAnut1p28yx3X',
    receptionDate: '2023-10-01T12:10:28.344Z',
    responseStatus: 'contacted',
    usageLocation: '病棟A',
    targetEquipment: '心電計',
    numberOfDevices: 2,
    contact: '090-8354-1582',
    wardRemarks: '特に問題なし',
    wardContactPerson: '川上 達也',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'NQtZyhn6YrsnKP1d',
    receptionDate: '2022-09-01T20:51:53.940Z',
    responseStatus: 'completed',
    usageLocation: '病棟A',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '5484',
    wardRemarks: '特に問題なし',
    wardContactPerson: '石川 真由',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'Mlk4rGW1LJkqK7rE',
    receptionDate: '2024-07-10T17:13:23.070Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟A',
    targetEquipment: 'モニター',
    numberOfDevices: 5,
    contact: '080-9274-4435',
    wardRemarks: '特に問題なし',
    wardContactPerson: '前田 和也',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'Hy80NA7Cp16fVXkV',
    receptionDate: '2023-05-01T17:54:13.019Z',
    responseStatus: 'pending_verification',
    usageLocation: '病棟B',
    targetEquipment: 'モニター',
    numberOfDevices: 2,
    contact: '2060',
    wardRemarks: '特に問題なし',
    wardContactPerson: '小川 未来',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'wX2I1wXjug7ToApM',
    receptionDate: '2022-11-13T00:12:07.366Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟C',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 2,
    contact: '9254',
    wardRemarks: '特に問題なし',
    wardContactPerson: '後藤 舞',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'W7tWIEaSkbnJs5Hp',
    receptionDate: '2024-02-26T22:55:19.809Z',
    responseStatus: 'in_preparation',
    usageLocation: '病棟C',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '6387',
    wardRemarks: '特に問題なし',
    wardContactPerson: '岡本 翔',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'CJI8V4n4qzmiqNHa',
    receptionDate: '2024-04-26T11:33:29.798Z',
    responseStatus: 'completed',
    usageLocation: '病棟A',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '080-6476-9963',
    wardRemarks: '特に問題なし',
    wardContactPerson: '長谷川 葵',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'xq28y1GTvCdNdxBs',
    receptionDate: '2023-10-11T13:04:08.738Z',
    responseStatus: 'contacted',
    usageLocation: '病棟A',
    targetEquipment: '心電計',
    numberOfDevices: 3,
    contact: '080-7512-8475',
    wardRemarks: '特に問題なし',
    wardContactPerson: '村上 智也',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'Ulwv8gVRHKheFktS',
    receptionDate: '2024-07-11T11:33:59.579Z',
    responseStatus: 'contacted',
    usageLocation: '病棟B',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '4468',
    wardRemarks: '特に問題なし',
    wardContactPerson: '近藤 優香',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: '19muFMMe49TKF3no',
    receptionDate: '2022-03-07T06:11:46.769Z',
    responseStatus: 'completed',
    usageLocation: '病棟A',
    targetEquipment: '輸液ポンプ',
    numberOfDevices: 1,
    contact: '7198',
    wardRemarks: '特に問題なし',
    wardContactPerson: '柴田 瑠奈',
    comments: '備考欄に記載するコメント例',
  },
  {
    hashId: 'jxUaNJpNjarINaaF',
    receptionDate: '2023-06-27T08:48:33.381Z',
    responseStatus: 'completed',
    usageLocation: '病棟B',
    targetEquipment: 'モニター',
    numberOfDevices: 3,
    contact: '080-5969-6878',
    wardRemarks: '特に問題なし',
    wardContactPerson: '三浦 拓也',
    comments: '備考欄に記載するコメント例',
  },
];
const EditButton = () => {
  return (
    <Button sx={{padding: 0, color: '#0052CC'}} startIcon={<EditIcon />}>
      編集
    </Button>
  );
};

const StatusIcon = ({status}: {status: ResponseStatusType}) => {
  const info = statusMap[status];
  return (
    <Box
      sx={{
        margin: 0,
        borderRadius: '12px',
        color: info.color,
        backgroundColor: info.backGroundColor,
        borderColor: info.color,
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'center',
      }}>
      {info.name}
    </Box>
  );
};

const useColumns = () => {
  const [tableLayout] = useTableLayout('returnWaitingReceptionList');
  const editButtonLayout: EnhancedColumn<ResponseAddComponent>[] = [
    {title: '対応内容の編集', field: 'editButton', fieldValue: () => <EditButton />},
  ];

  const columns = React.useMemo(() => {
    if (!tableLayout.currentLayout || tableLayout.currentLayout.length === 0) return [];
    const mColumns = Object.assign<EnhancedColumn<ResponseAddComponent>[], TableLayout[]>(
      [],
      tableLayout.currentLayout
    ).map((column) => {
      switch (column.field) {
        case 'receptionDate':
          column.fieldValue = (item) => dayjs(item.receptionDate).format('YYYY年MM月DD日 HH:mm');
          break;
        case 'responseStatus':
          column.fieldValue = (item) => <StatusIcon status={item.responseStatus} />;
          break;
        //TODO:おそらく小分類のHashIdが返ってくるはずなので取得する
        case 'targetEquipment':
          column.fieldValue = (item) => item.targetEquipment;
          break;

        default:
          break;
      }
      column.sorting = true;
      return column;
    });
    return [...editButtonLayout, ...mColumns].map((column) => ({
      ...column,
      noBodyWrap: true,
    }));
  }, [tableLayout.currentLayout]);

  return columns;
};

export const ReturnWaitingReceptionListTable = React.memo(
  () => {
    const columns = useColumns();
    return (
      <EnhancedTable<ResponseAddComponent>
        data={dummyData}
        columns={columns}
        isLoading={columns.length === 0}
        stickyHeader
        stickyColumn
        noDataComponent={<NoContentMessage title={'部品が登録されていません'} />}
        defaultOrder={{fieldId: 'receptionDate', direction: 'desc'}}
      />
    );
  },
  () => true
);
