import {z} from 'zod';

/**
 * 返却待ち受付APIのリクエストのバリデーションスキーマ
 */
export const returnsReservationRequestSchema = z.object({
  narrowCategoryHashId: z.string().min(1),
  roomHashId: z.string().min(1),
  reservationCount: z
    .number()
    .min(1) // 1以上の数値
    .max(20),
  contactInformation: z.string().optional(),
  transitionMessage: z.string().optional(),
});

export type ReturnsReservationRequest = z.infer<typeof returnsReservationRequestSchema>;
