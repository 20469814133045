import React, {memo} from 'react';
import {Box, Button, SxProps} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {StrUtil} from '@front-libs/helpers';

export type FooterProps = {
  text?: string;
  backButtonHandler?: VoidFunction;
  nextButton?: {
    label: string;
    disabled?: boolean;
    buttonHandler: VoidFunction;
  };
  hideBackButton?: boolean;
};
/**
 * フッターを表示するコンポーネント
 * 戻るボタン、テキスト、およびオプションの次へボタンを含む
 *
 * @todo SharedFooterに移行予定
 *
 * @param {string} props.text - フッターに表示するテキスト
 * @param {VoidFunction} props.backButtonHandler - 「戻る」ボタンのクリックイベントハンドラ
 * @param {Object} [props.nextButton] - 「次へ」ボタンの設定。`label`はボタンに表示するテキスト、`disabled`はボタンが無効化されているかどうかを示し、`buttonHandler`はクリックイベントハンドラです。
 * @param {string} props.nextButton.label - 「次へ」ボタンに表示するテキスト
 * @param {boolean} props.nextButton.disabled - 「次へ」ボタンが無効かどうか
 * @param {VoidFunction} props.nextButton.buttonHandler - 「次へ」ボタンのクリックイベントハンドラ
 * @param {boolean} props.hideBackButton - 「戻る」ボタンの非表示にするかどうか(trueで非表示)
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { Footer } from './Footer';
 *
 * function MyApp() {
 *   const handleBack = () => {
 *     console.log('Back button clicked');
 *   };
 *
 *   const handleNext = () => {
 *     console.log('Next button clicked');
 *   };
 *
 *   return (
 *     <Footer
 *       text="ページの説明"
 *       backButtonHandler={handleBack}
 *       nextButton={{
 *         label: "次へ",
 *         disabled: false,
 *         buttonHandler: handleNext
 *       }}
 *     />
 *   );
 * }
 * ```
 * この例では、`Footer`コンポーネントを使用して、「戻る」と「次へ」のボタンが含まれるフッターをレンダリングします。
 * `text`プロパティにはフッターに表示したいテキストを、`backButtonHandler`には「戻る」ボタンのイベントハンドラを、
 * `nextButton`には「次へ」ボタンの設定を渡しています。
 */
export const Footer = memo(
  ({text, backButtonHandler, nextButton, hideBackButton}: FooterProps) => {
    return (
      <Box sx={ContainerStyle}>
        {!hideBackButton && backButtonHandler && (
          <Button data-testid={'rental-footer-back-button'} onClick={backButtonHandler} sx={BackButtonStyle}>
            <ChevronLeft sx={ChevronLeftStyle} />
            <Box sx={ButtonTextStyle}>{'戻る'}</Box>
          </Button>
        )}
        <Box sx={TextStyle}>{text && StrUtil.nl2br(text)}</Box>
        {nextButton && (
          <Button
            variant={'outlined'}
            disabled={nextButton.disabled}
            onClick={nextButton.buttonHandler}
            sx={NextButtonStyle}>
            <Box sx={NextBtnTextStyle}>{nextButton.label}</Box>
            <ChevronRight sx={ChevronRightStyle} />
          </Button>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    // NextButtonのdisabledが変更された時のみレンダリング対象
    return prevProps.nextButton?.disabled === nextProps.nextButton?.disabled;
  }
);

const ContainerStyle: SxProps = {
  backgroundColor: '#0052CC',
  height: '128px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
};

const ButtonTextStyle: SxProps = {
  paddingTop: '4px',
  paddingRight: '24px',
  fontSize: '24px',
  minWidth: '52px',
};

const NextBtnTextStyle: SxProps = {
  paddingTop: '4px',
  paddingLeft: '24px',
};

const ChevronLeftStyle: SxProps = {
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
};

const ChevronRightStyle: SxProps = {
  paddingTop: '4px',
  paddingRight: '8px',
  fontSize: '24px',
};

const TextStyle: SxProps = {
  color: '#ffffff',
  fontSize: '22px',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '40%',
  '@media (min-width: 768px)': {
    fontSize: '32px',
    width: '60%',
    lineHeight: '1.2em',
  },
  '@media (min-width: 1024px)': {
    fontSize: '36px',
    width: '100%',
  },
  '@media (min-width: 1280px)': {
    fontSize: '36px',
    width: '100%',
  },
};

const ButtonStyle: SxProps = {
  backgroundColor: '#ffffff',
  color: '#0052CC',
  fontSize: '18px',
  fontWeight: 'bold',
  height: '60px',
  padding: '5px 0px 5px 8px',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  boxShadow: '0px 4px 0px 0px rgba(209, 216, 245, 1)',
};

const BackButtonStyle: SxProps = {
  ...ButtonStyle,
  position: 'absolute',
  left: '0px',
  width: '108px',
  borderRadius: '0px 45px 45px 0px',
  border: 0,
  '@media (min-width: 768px)': {
    width: '140px',
  },
  '@media (min-width: 1024px)': {
    width: '160px',
    fontSize: '20px',
  },
  '@media (min-width: 1280px)': {
    width: '176px',
    fontSize: '24px',
    padding: '5px 15px',
  },
  '& .MuiButton-label': {
    justifyContent: 'left',
  },
};

const NextButtonStyle: SxProps = {
  ...ButtonStyle,
  position: 'absolute',
  right: '0px',
  borderRadius: '45px 0px 0px 45px',
  border: 0,
  '@media (min-width: 1024px)': {
    fontSize: '20px',
  },
  '@media (min-width: 1280px)': {
    fontSize: '24px',
    padding: '5px 15px',
  },
};
