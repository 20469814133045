import React, {useMemo, useState} from 'react';
import request from 'axios';
import {CategoryTable} from './CategoryTable';
import TableForm from './TableForm';
import {productsAtom, InitialValue, productCountAtom, Product} from '../state';
import {ProductIndex} from '@modules/products/types';
import {
  Box,
  Dialog,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions as MDialogActions,
  Button,
} from '@material-ui/core';
import {styled} from '@material-ui/styles';
import {useAtom} from 'jotai';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {dialogHandler} from '@molecules/Dialogs/DialogHandler';
import {MessageDialog} from '@molecules/Dialogs/MessageDialog';
import {useMyInfo} from '@modules/hospital_users/hooks/useMyInfo';
import {useHospitalProduct} from '@modules/hospital_products/hooks/useHospitalProduct';
import {useHospitalProductRequiredPropertyStore} from '@modules/hospital_product_required_properties/hooks';
import {HospitalProductRequiredPropertyItemValue} from '@modules/hospital_product_required_properties/types';
import {useEffectOnce} from 'react-use';
import {v4 as uuidv4} from 'uuid';
import {getProduct} from '@modules/products/api';
import {useAsyncEffect, useOnlyOnce} from '@front-libs/core';
import {useAtomValue} from 'jotai';
import {openSnackBar} from '@components/molecules/SnackBar';
import {AlertDialog} from '@molecules/Dialogs/AlertDialog';
import {MEIDAI_HIT_5368_IS_BASE_UNIT} from '@constants/constants';
import {convertToBulkCreate} from './helper';

type ProductRegistrationState = {
  fromGs1?: boolean;
  fromCopy?: boolean;
};

export const Step2: React.FC = () => {
  const {hashId} = useParams();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {myInfo} = useMyInfo();
  const count = useAtomValue(productCountAtom);
  const {bulkCreateHospitalProducts} = useHospitalProduct();
  const {
    state: {hospitalProductRequiredProperties},
    dispatchListHospitalProductRequiredProperties,
  } = useHospitalProductRequiredPropertyStore();

  const [products, setProducts] = useAtom(productsAtom);
  const [wholeProduct, setWholeProduct] = useState<ProductIndex>();

  const fromGs1 = useMemo(() => (state as ProductRegistrationState)?.fromGs1 ?? false, [state]);
  const fromCopy = useMemo(() => (state as ProductRegistrationState)?.fromCopy ?? false, [state]);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffectOnce(() => {
    //Memo: Step1ページのcountに応じて、productsの数を作成する。
    // GS1または複製登録の場合、既にproductsが設定されている可能性があるので、差分を計算してから設定。
    // countとproductsはAtomからGet。
    const actualCount = fromGs1 || fromCopy ? count - products.length : count;
    const createProductsByCount = [...Array(actualCount < 0 ? 0 : actualCount)].map(() => {
      return {...InitialValue, uuid: uuidv4(), wholeProductHashID: hashId};
    });

    setProducts([...(fromGs1 || fromCopy ? products : []), ...createProductsByCount]);

    dispatchListHospitalProductRequiredProperties(myInfo.hospitalHashId);
  });

  useAsyncEffect(async () => {
    //Memo: hashIdでメモ化することで、何度もリクエストを送らないようにする。
    const wholeProductIndex = await getProduct(hashId ? hashId : '');
    setWholeProduct(wholeProductIndex);
  }, [hashId]);

  const handleSubmit = async () => {
    try {
      await dialogHandler.open(MessageDialog, {
        title: '一括登録を実行しますか？',
        content: `リストに存在する${products.length}件の機器が登録されます。\n登録を実行しますか？`,
        positiveButtonLabel: '登録',
      });

      const newHospitalProducts = MEIDAI_HIT_5368_IS_BASE_UNIT ? convertToBulkCreate(products) : products;

      await bulkCreateHospitalProducts(myInfo.hospitalHashId, {
        hospital_products: newHospitalProducts,
      });
      openSnackBar('機器を追加登録しました。');
      navigate('/products');
    } catch (e) {
      if (request.isAxiosError(e) && e.response) {
        const errorMessage = e.response.data.message;
        if (errorMessage.includes('conflicting management ID')) {
          openSnackBar('この管理番号は使用されているため、保存できません。', 'left', 'bottom', 'error');
          return;
        }
      }

      openSnackBar('機器の追加登録に失敗しました。', 'left', 'bottom', 'error');
    }
  };

  useOnlyOnce(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isBlocked = searchParams.get('isBlocked') === 'true';
    if (isBlocked) {
      setIsBlocked(true);
    }
  });

  const checkProductRequiredProperties = (product: Product): boolean => {
    if (!product.managementId) {
      return false;
    }
    return hospitalProductRequiredProperties.every((item) => {
      switch (item.property as HospitalProductRequiredPropertyItemValue) {
        case HospitalProductRequiredPropertyItemValue.SerialNumber:
          return Boolean(product.serialNumber);
        case HospitalProductRequiredPropertyItemValue.LotNumber:
          return Boolean(product.lotNumber);
        case HospitalProductRequiredPropertyItemValue.HospitalDepartment:
          return Boolean(product.hospitalDepartmentHashId);
        case HospitalProductRequiredPropertyItemValue.Status:
          return Boolean(product.status);
        case HospitalProductRequiredPropertyItemValue.HospitalRoom:
          return Boolean(product.hospitalRoomHashId);
        case HospitalProductRequiredPropertyItemValue.DateOfPurchase:
          return Boolean(product.dateOfPurchase);
        case HospitalProductRequiredPropertyItemValue.WaysOfPurchase:
          return Boolean(product.waysOfPurchase);
        case HospitalProductRequiredPropertyItemValue.LegalDurableYear:
          return product.legalDurableYear !== undefined;
        case HospitalProductRequiredPropertyItemValue.HospitalDealer:
          return Boolean(product.hospitalDealerHashId);
        case HospitalProductRequiredPropertyItemValue.AssetRegisterNumber:
          return Boolean(product.assetRegisterNumber);
        case HospitalProductRequiredPropertyItemValue.DateOfDisposal:
          return Boolean(product.dateOfDisposal);
        case HospitalProductRequiredPropertyItemValue.ReasonOfDisposal:
          return Boolean(product.reasonOfDisposal);
        case HospitalProductRequiredPropertyItemValue.OptionalBarcode:
          return Boolean(product.optionalBarcode);
        case HospitalProductRequiredPropertyItemValue.Gs1Barcode:
          return Boolean(product.rawBarcode);
      }
      return true;
    });
  };

  const canSubmit = useMemo(() => {
    return products.length > 0 && products.every(checkProductRequiredProperties);
  }, [products]);

  const handleBack = async () => {
    if (isBlocked) {
      try {
        await dialogHandler.open(AlertDialog, {
          title: '情報を削除して一覧に戻りますか？',
          content: 'このページを離れると、機種及び機器情報が削除されます。\n一覧に戻りますか？',
          positiveButtonLabel: '一覧に戻る',
        });
      } catch (_error) {
        // キャンセル押下時
        return;
      }
    }

    navigate('/products');
  };

  return (
    <Dialog fullScreen={true} open={true} fullWidth maxWidth={'md'} disableEnforceFocus={true}>
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="subtitle1" component="div">
          HITOTSUに機器を登録
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={'column'}>
          <DialogContentBox mb={4}>
            <Typography variant="h5" component="div">
              登録内容の確認
            </Typography>
          </DialogContentBox>
          <DialogContentBox mb={4}>
            <CategoryTable wholeProducts={wholeProduct} />
          </DialogContentBox>
          <DialogContentBox>
            <TableForm
              hashId={hashId ? hashId : ''}
              wholeProduct={wholeProduct}
              hospitalProductRequiredProperties={hospitalProductRequiredProperties}
              fromGs1={fromGs1}
            />
          </DialogContentBox>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box p={2} width={'100%'} display={'flex'} justifyContent={'space-between'}>
          <Button color="primary" variant="outlined" onClick={handleBack}>
            一覧に戻る
          </Button>
          <Button
            style={{marginRight: 80}}
            disabled={!canSubmit}
            color="primary"
            variant="contained"
            onClick={handleSubmit}>
            登録
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const DialogActions = styled(MDialogActions)({
  background: '#FFFFFF',
  padding: 'initial',
});

const DialogContentBox = styled(Box)({
  maxWidth: '100%',
});
